
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import dynamic from 'next/dynamic';
// import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
const Container = dynamic(() => import('@mui/system/Container').then(module => module.default));
const Layout = dynamic(() => import('../../@core/components/common/layout').then(module => module.Layout));
const BannerImage = dynamic(() => import('../../@core/components/common/design/coloredBanner').then(module => module.default));
const Grid = dynamic(() => import('@mui/material/Grid').then(module => module.default));
const Button = dynamic(() => import('@mui/material/Button').then(module => module.default));
const Card = dynamic(() => import('@mui/material/Card').then(module => module.default));
const CardContent = dynamic(() => import('@mui/material/CardContent').then(module => module.default));
const Typography = dynamic(() => import('@mui/material/Typography').then(module => module.default));
type ErrorProps = {
    statusCode: number;
};
type InitialProps = {
    res: any;
    err: any;
};
function Error({ statusCode }: ErrorProps) {
    // const { t, lang } = useTranslation()
    const router = useRouter();
    const { t, lang } = useTranslation();
    return (<Layout page={'home'} head={"404"} route={"/404"} title={""} loading={false}>

            <Grid sx={{
            backgroundColor: '#fcfcfc',
        }}>
                <Container sx={{ minHeight: '100vh', overflowY: 'auto', }}>
                    <Grid height={"100vh"} display={'flex'} justifyContent={'center'} alignItems={"center"}>
                        <Grid display={'flex'} justifyContent={'center'} alignItems={"center"} flexDirection={"column"}>
                            <Grid sx={{ width: { md: 600, xs: "100%" }, height: { md: 300, xs: 100 }, position: 'relative' }}>
                                <Image priority={true} src={"/static/images/404.gif"} alt={"Diabafrica 404 Error"} fill/>
                            </Grid>
                            <Grid mt={4}>
                                <Typography color={'primary'} variant='h5'>

                                    {statusCode
            ? statusCode == 404 ? `404 - Page Not Found` : `An error ${statusCode} occurred on server`
            : 'An error occurred on client'}
                                </Typography>
                            </Grid>
                            <Grid mt={4}>
                                <Button variant='contained' onClick={() => {
            router.push("/", undefined);
        }} color='secondary' size='medium' sx={{}}>
                                    home
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Layout>);
}
const getStaticProps = ({ res, err }: InitialProps) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return { props: { statusCode: statusCode } };
};
export default Error;

    async function __Next_Translate__getStaticProps__193afb3df78__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/_error',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193afb3df78__ as getStaticProps }
  